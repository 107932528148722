<template>
  <div class="boxComplete">
    <input
      @input.stop="inputHandler"
      @keyup.up="upHandler"
			@keyup.down="downHandler"
      @keypress.enter.stop.prevent="enterHandler"
      :name="aname" autocomplete="off" size="28"
      :id="aid"
      :placeholder="aph || voc.ph[lang]"
      type="text"
      :class="['search', aclass]"
      :value="selectedResult"
    >
    <ul id="searchAdvice" v-show="model.length"
			@mouseover="hoverHeader"
			@click="selectHandler"
		>
			<li v-for="(item, index) in model" v-html="formatResult(item.name_ru, item.name_en)" :iid="item.id"/>
		</ul>
  </div>
</template>

<script>
import translations from './translations';

export default {
  name: "v-autocomplete",
  props: {
    result: Array,
    aname: String,
    aclass: String,
    aid: {
      type: String,
      default: 'v-search-id'
    },
    aph: String,
    lang: String
  },
  data(){
    return {
      index: -1,
      selectedResult: '',
      model: [],
      hack:'',
      voc: translations
    }
  },
  watch: {
    result(val){
      this.model = val
      if (val.length)
				this.hack = this.selectedResult;
    },
  },
  methods: {
    enterHandler(e){
			if (this.index>-1) //ентер
			{
				this.$emit('v-choice', {data: this.model[this.index], el:e.target});
				this.removeResult();
			}
		},
    selectHandler(e){
			let target=e.target;
			if (target.nodeName==='B')
				target=target.parentNode;
			if (target.nodeName==='LI')
			{
        console.log(this.index)
				let iid = target.getAttribute('iid');
        for(var i=0; i<this.model.length; i++){
          if(this.model[i].id == iid) this.index = i
        }
				//this.selectedResult = this.model[this.index].name_ru;
				let input = target.parentElement.previousElementSibling;
				input.focus();
				this.$emit('v-choice', {data: this.model[this.index], el:input});
			}
		},
    inputHandler(e){
			this.selectedResult = e.target.value;
			this.$emit('v-search', {data: e.target.value, el: e.target});
			if (e.target.value == '')
				this.removeResult();
		},
    hoverHeader(e){
			let target = e.target;
			if (target.nodeName==='B')
				target=target.parentNode;
			if (target.nodeName==='LI')
			{
				let lih = target.parentNode.querySelector('.hover');
				if (lih)
					lih.className = '';

				target.className = 'hover';

				this.index = +target.getAttribute('iid');
			}
		},
    upHandler(e){
			if (this.index>0) //вверх
			{
				let UL = e.target.nextElementSibling;
				let li = UL.querySelectorAll('li');
				li[this.index].className='';
				this.index--;
				let eLi=li[this.index];
				eLi.className='hover';
				if (/*(UL.scrollTop>0)&&*/((eLi.offsetTop-UL.scrollTop)<0)){
					UL.scrollBy(0,-eLi.offsetHeight);
					//eLi.scrollIntoView(true);
				}
			}
		},
		downHandler(e){
			if(this.index < (this.model.length-1) ) //вниз
			{
				let UL = e.target.nextElementSibling;
				let li = UL.querySelectorAll('li');
				this.index++;
				if (this.index>0)
					li[this.index-1].className='';
				let eLi=li[this.index];
				eLi.className='hover';

				if (/*(UL.scrollTop<(UL.scrollHeight-UL.clientHeight))&&*/((eLi.offsetTop+eLi.offsetHeight-UL.scrollTop)>=UL.clientHeight)){
					UL.scrollBy(0,eLi.offsetHeight);
					//eLi.scrollIntoView(false);
				}
			}
    },
    formatResult(result_ru, result_en){
			//return result.replace(new RegExp(this.selectedResult, 'gi'),'<b>$&</b>');
			if(result_ru != null) var ru = result_ru.replace(new RegExp(this.hack, 'gi'),'<b>$&</b>');
      if(result_en != null) var en = result_en.replace(new RegExp(this.hack, 'gi'),'<b>$&</b>');
      var ifRuPattern = /[а-я0-9\s\W]/gi;
      if(ifRuPattern.test(this.hack)){
        return ru
      } else { return en}
		},
    removeResult(){
			this.model = [];
      this.selectedResult='';
			document.body.removeEventListener('click', this.hideResult);
      this.$emit('v-clear');
		},
		hideResult(e){
			if (e.target.id != this.aid)
				this.removeResult();
		},
  },
  updated(){
		this.index = -1;
		if (this.model.length)
			document.body.addEventListener('click', this.hideResult);
	},
}
</script>
